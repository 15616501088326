import { ProjectData } from "./ProjectData";
import styles from './Projects.module.css'

function Projects() {
    return(
        <>
        <div className={styles.bg} id='projects'>
            <div>
                <ul className={`${styles.list__head}`}>
                    {ProjectData.map((item, index) => {
                        return (
                            <li key={index}>
                                <a href={item.path}
                                className={`${styles.project__link}`}
                                >
                                    <img alt='' src={`${item.icon}`} className={`${styles.project__icon}`}>
                                    </img>
                                    <h1>{item.title}</h1>
                                </a>
                                <p className={`${styles.project__description}`}>{item.description}</p>
                                <div className={styles.demo}>
                                    {item.screenshot && (
                                        <img alt='' src={`${item.screenshot}`} className={`${styles.project__demo}`}/>
                                    )}
                                    {item.screenshot2 && (
                                        <img alt='' src={`${item.screenshot2}`} className={`${styles.project__demo}`}/>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
        </>
    );
}

export default Projects;