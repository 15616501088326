import About from '../About';
import Projects from '../Projects';
import Header from '../Header';
import NavBar from '../../components/NavBar';

const Home = () => {
    return (
      <div className='device'>
        <NavBar/>
        <Header/>
        <About/>
        <Projects/>
      </div>
    )
}

export default Home;