
import styles from './About.module.css'
import Github from '../../images/social_icons/Github.svg'
import LinkedIn from '../../images/social_icons/LinkedIN.svg'
import Email from '../../images/social_icons/Email.svg'
import Email_DarkMode from '../../images/social_icons/Email2.svg'

function About() {

    return(
        <div className={`${styles.header} theme`} id='about'>
            <h1 className={styles.widthBlock}>
                Hello, I'm Elijah Larios, a developer who has been writing websites since 2014. I started doing backend work in 2020, allowing me to transition to a full stack developer. 
                <a href='https://github.com/elijahlarios'>
                    <img src={Github} className={styles.svg} alt=''></img>
                </a>  
                <a href='https://www.linkedin.com/in/elijah-larios'>
                    <img src={LinkedIn} className={styles.svg} alt=''></img>
                </a>
                <a href='mailto:larioselijah@gmail.com'>
                    <img src={Email} className={styles.svg} alt=''></img>
                </a>
            </h1>
            </div>
    );
}

export default About;