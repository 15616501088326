// app icons
import tunitIcon from '../../images/project_icons/tunitIcon.png'
import tribeIcon from '../../images/project_icons/tribeIcon.png'
import websiteIcon from '../../images/project_icons/websiteIcon.png'
import iosAppIcon from '../../images/project_icons/iosAppIcon.png'
import tttIcon from '../../images/project_icons/tttIcon.png'
import fpsIcon from '../../images/project_icons/fpsIcon.png'
import scenicIcon from '../../images/project_icons/scenicIcon.png'

// app screenshots
import ttt1 from '../../images/project_demo/ttt1.png'
import ttt2 from '../../images/project_demo/ttt2.png'
import fps1 from '../../images/project_demo/fps1.png'
import fps2 from '../../images/project_demo/fps2.png'
import scenic from '../../images/project_demo/scenic.png'



export const ProjectData = [
    {
        title: 'Tunit',
        path: 'https://github.com/Tunit-AI/tunit-public',
        icon: tunitIcon,
        // screenshot: '',
        description: 'Tunit is an AI music recommendation system built with React, Firebase, and deployed to Azure. My role in this project was sole frontend developer. It is currently in beta, and reduced source code is available on  my Github.'

    },
    {
        title: 'Tic Tac Toe',
        path: 'https://github.com/elijahlarios/FPS',
        icon: tttIcon,
        screenshot: ttt1,
        screenshot2: ttt2,
        description: 'A cross-platform Tic Tac Toe game implementing several AI algorithms for computer movement. Allows for multiple grid sizes and a variety of other customization options for both the user and computer. Created using Python with PyQt6.'

    },
    {
        title: 'FPS',
        // path: '',
        icon: fpsIcon,
        screenshot: fps1,
        screenshot2: fps2,
        description: 'FPS is an immersive First-Person Shooter (FPS) game set in a post-apocalyptic world overrun by zombies. Written in C# with Unity.'

    },
    {
        title: 'Scenic',
        // path: '',
        icon: scenicIcon,
        screenshot: scenic,
        description: 'Scenic is a Computer Graphics programming project which utilizes the C++ programming language with OpenGL frameworks and turtle canvas drawing functions to create a beautiful visual experience. The primary objective of this project is to simulate a day’s passing, with a seamless transition between morning and night, in a continuous loop until the program is halted.'

    },

    {
        title: 'emojiMatch',
        // path: '',
        icon: iosAppIcon,
        // screenshot: '',
        description: "This specific app is a card matching game where emojis are used instead of faces. This was developed with Swift and SwiftUI. "

    },
    {
        title: 'Tribe (WIP)',
        // path: '',
        icon: tribeIcon,
        // screenshot: '',
        description: "Tribe is a new way to socialize with your contacts. More info coming soon :)"
    },
    {
        title: 'elijah larios dot com',
        path: '',
        icon: websiteIcon,
        description: "I developed this website over this past week from scratch, I implemented techniques learned during my time working as the frontend developer for Tunit."
    },

]