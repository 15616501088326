import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import './styles.css';
import NavBar from './components/NavBar';
import Home from './pages/Home/';
import * as ROUTES from './constants/routes.js'

const App = () => {
  return (
    // <div className="App">
    //   <NavBar/>
    //   <Home/>
    // </div>
    <>
        <NavBar />
        <Routes>
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.UNAUTHORIZED} element={
                <div className='home fourohfour'>
                    <div>
                        <div className='fof__break'></div>
                        <div>
                            <h1>Uh-oh...</h1>
                        </div>
                        <div className='fof__break'></div>
                        <div>
                            <h2>That's not a link!</h2>
                        </div>
                        <div className='fof__break'></div>
                        <h3>
                            <Link to='/'>
                                Return Home
                            </Link>
                        </h3>
                        <div className='fof__break'></div>
                    </div>
                </div>
            } />
        </Routes>
    </>
    
  )
}

export default App;
