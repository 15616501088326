export const MenuData = [
    {
        path: '#header',
        cName: 'nav-links',
        icon: 'fa-solid fa-house-user'
    },
    {
        path: '#about',
        cName: 'nav-links',
        icon: 'fa-solid fa-circle-question'
    },
    {
        path: '#projects',
        cName: 'nav-links',
        icon: 'fa-solid fa-folder-open'
    },

]