import "./NavBar.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { MenuData } from "./MenuData";
class NavBar extends Component {
    state = {clicked: false};
    handleClick = () => {
        this.setState({clicked: !this.state.clicked});
    }
    render() {
        return (
            <nav className="navbar">
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
                <ul className={this.state.clicked ? "nav__menu active" : "nav__menu"}>
                    {MenuData.map((item, index) => {
                        return (
                            <li key={index}>
                                <a href={item.path} 
                                className={item.cName}>
                                    <i className={item.icon}></i>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        );
    }
}

export default NavBar;