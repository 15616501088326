import React from "react";
import styles from './Header.module.css'
import me from '../../images/me.JPG'

function Header() {
    return(
        <div className='theme' id='header'>
            <div className={styles.header} id=''>
                <div className={styles.columns}>
                    <div>
                        <h1 className={styles.title}>Hi ! I'm </h1>
                        <h1 className={`${styles.title} ${styles.name}`}>Elijah Larios.</h1>
                    </div>
                    <div className={`${styles.desktop}`}>
                        <h1>Hi ! I'm Elijah Larios.</h1>
                    </div>
                </div>
                <div className={styles.center}>
                    <img className={styles.circular} alt = '' src={me}/>
                </div>
            </div>
        </div>
    )
}

export default Header;